import React from "react";
import { object } from "prop-types";
import { graphql } from "gatsby";

import { refactorDate, makePostsLinkDate } from "shared/functions";
import { BlogEntry } from "components/BlogEntry";
import { Layout } from "components/Layout";
import { Navbar } from "components/Navbar";

export default function PostListTemplate({
  data: { allMdx },
  location,
  pageContext,
}) {
  const posts = allMdx.edges;
  const titleAddition =
    pageContext.currentPage !== 1
      ? "Strona " + pageContext.currentPage + " - "
      : "";
  return (
    <Layout
      location={location}
      title={
        "www.srebniak.pl - " +
        titleAddition +
        "programowanie, linux, Yii framework"
      }
    >
      {posts.map(({ node }) => (
        <BlogEntry
          key={node.id}
          date={refactorDate(node.frontmatter.date)}
          title={node.frontmatter.title}
          author={node.frontmatter.author}
          heroImage={node.frontmatter.heroImage}
          description={node.frontmatter.description}
          categories={node.frontmatter.categories}
          path={"/" + makePostsLinkDate(node.frontmatter.date) + node.slug}
        />
      ))}
      <Navbar
        currentPage={pageContext.currentPage}
        pageNumber={pageContext.numPages}
        link="/"
      />
    </Layout>
  );
}

PostListTemplate.propTypes = {
  data: object.isRequired,
  mdx: object,
  location: object.isRequired,
  pageContext: object.isRequired,
};

export const pageQuery = graphql`
  query ListQuery($skip: Int!, $limit: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { fields: { source: { eq: "posts" } } }
    ) {
      edges {
        node {
          slug
          id
          frontmatter {
            title
            date
            author {
              name
              link
            }
            categories {
              name
              subcategoryOf
              link
            }
            description
            heroImage {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
